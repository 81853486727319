import { useEffect } from "react";
import Layout from "../layout/Layout";

function FortComponent() {
    useEffect(() => {
        function convertToArabicNumbers(str) {
            return str.replace(/\d/g, function(d) {
                return '٠١٢٣٤٥٦٧٨٩'[d];
            });
        }

        document.querySelectorAll('input').forEach(function(input) {
            input.addEventListener('input', function() {
                this.value = convertToArabicNumbers(this.value);
            });
        });

    }, []); // استفاده از [] برای اطمینان از اجرای این کد فقط یکبار

    return (
        <Layout>
            <div className="container container-form">
                <div className="row g-3 bg-white p-3 mt-3 rounded">
                    <div className="col-md-4">
                        <label htmlFor="province" className="form-label">القضاء:</label>
                        <input type="text" className="form-control" id="province" placeholder="ادخل القضاء" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="subdistrict" className="form-label">الناحية:</label>
                        <input type="text" className="form-control" id="subdistrict" placeholder="ادخل الناحية" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="district" className="form-label">المحلة ورقم القطعة دا:</label>
                        <input type="text" className="form-control" id="district" placeholder="ادخل المحلة ورقم القطعة" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="enrollment" className="form-label">التسجيل الدراسي:</label>
                        <input type="text" className="form-control" id="enrollment" placeholder="ادخل التسجيل الدراسي" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="national-id" className="form-label">رقم القافلة:</label>
                        <input type="text" className="form-control" id="national-id" placeholder="ادخل رقم القافلة" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="job-location" className="form-label">موقع العمل الحالي:</label>
                        <input type="text" className="form-control" id="job-location" placeholder="ادخل موقع العمل" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="full-name" className="form-label">الاسم الرباعي واللقب:</label>
                        <input type="text" className="form-control" id="full-name" placeholder="ادخل الاسم الرباعي واللقب" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="study-period" className="form-label">التحصيل الدراسي:</label>
                        <input type="text" className="form-control" id="study-period" placeholder="ادخل التحصيل الدراسي" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="current-location" className="form-label">موقع السكن الحالي:</label>
                        <input type="text" className="form-control" id="current-location" placeholder="ادخل موقع السكن" />
                    </div>
                </div>

                <button className="btn save-btn">حفظ</button>

                <div className="bg-white p-3 mt-3 rounded">
                    <h2 className="text-center">معلومات الأسرة</h2>
                    <table className="table table-bordered mt-3">
                        <thead className="table-light">
                            <tr>
                                <th>الاسم الثلاثي</th>
                                <th>صلة القرابة</th>
                                <th>المرحلة</th>
                                <th>التسجيل الدراسي</th>
                                <th>العمل</th>
                                <th>موقع العمل</th>
                                <th>الحالة الاجتماعية</th>
                                <th>رقم البطاقة</th>
                                <th>اسم الأم</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <td>1</td>
                                <td>نجيبة علي محسن</td>
                                <td>الأم</td>
                                <td>١٩٥٤ دبلوم</td>
                                <td>ربة بيت</td>
                                <td>بلا</td>
                                <td>أرملة</td>
                                <td>٧٨٩٩٢٣٤</td>
                                <td>مسون علي</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>أمل عودة سلمان</td>
                                <td>زوجة</td>
                                <td>١٩٥٥ دبلوم</td>
                                <td>ربة بيت</td>
                                <td>بلا</td>
                                <td>متزوجة</td>
                                <td>٨٧٦٢٤٣</td>
                                <td>زهراء داوود</td>
                            </tr>
                            <tr>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
}

export default FortComponent;
