import { Link } from "react-router-dom";
import Layout from "../layout/Layout";


function FiftComponent() {
    return (
        <Layout>
            <div className="container bg-white py-3">
                {/* فیلدهای بالای جدول */}
                <div className="header-fields">
                    <div className="row">
                        <div className="col-md-4">
                            <p>القضاء: البصرة</p>
                        </div>
                        <div className="col-md-4">
                            <p>الناحية: الطويسة</p>
                        </div>
                        <div className="col-md-4">
                            <p>المنطقة وأقرب نقطة دالة: الطويسة/ جامع الرحمة</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <p>التحصيل الدراسي: دبلوم/ معهد التقني
                            </p>
                        </div>
                        <div className="col-md-4">
                            <p>العمل وموقته: كاسب/ العشار</p>
                        </div>
                        <div className="col-md-4">
                            <p>موقع السكن الحالي: الطويسة</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <p>رقم الهاتف: ٠٧٧٠٩٧٦٥٤٣٢</p>
                        </div>
                        <div className="col-md-4">
                            <p>موقع السكن السابق: الحكيمية</p>
                        </div>
                        <div className="col-md-4">
                            <p>الاسم الرباعي واللقب: هادي عباس أيوب النجار</p>
                        </div>
                    </div>
                </div>

                {/* جدول */}
                <div className="">
                    <table className="table table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th>ت</th>
                                <th>الاسم الثلاثي</th>
                                <th>مسألة القرابة</th>
                                <th>الوالید</th>
                                <th>التسجيل الدراسي</th>
                                <th>العمل</th>
                                <th>سوق العمل</th>
                                <th>الحالة الاجتماعية</th>
                                <th>رقم الهوية أو البطاقة الموحدة</th>
                                <th>اسم الأم</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>نجيبة علي محسن</td>
                                <td>الأم</td>
                                <td>١٩٥٤ </td>
                                <td> دبلوم</td>
                                <td>ربة بيت</td>
                                <td>بلا</td>
                                <td>أرملة</td>
                                <td>٧٨٩٩٢٣٤</td>
                                <td>مسون علي</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>أمل عودة سلمان</td>
                                <td>زوجة</td>
                                <td>١٩٥٥</td>
                                <td> دبلوم</td>
                                <td>ربة بيت</td>
                                <td>بلا</td>
                                <td>متزوجة</td>
                                <td>٨٧٦٢٤٣</td>
                                <td>زهراء داوود</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>هدى عباس أيوب</td>
                                <td>الأخت</td>
                                <td>١٩٩١</td>
                                <td> بكلوريوس</td>
                                <td>موظفة</td>
                                <td>دائرة الصحة</td>
                                <td>باكر</td>
                                <td>٧٨٤٥٧٨</td>
                                <td>نجيبة علي</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>زینب هادي عباس</td>
                                <td>الأبنة</td>
                                <td>٢٠١٠</td>
                                <td> طالبة</td>
                                <td>بلا</td>
                                <td>بلا</td>
                                <td>باكر</td>
                                <td>٩٩٨٧٦٥٨</td>
                                <td>أمل عودة</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>زین العابدين هادي عباس</td>
                                <td>الأبن</td>
                                <td>٢٠١٢</td>
                                <td> طالب</td>
                                <td>بلا</td>
                                <td>بلا</td>
                                <td>أعزب</td>
                                <td>٩٩٤٣٢١٧٨</td>
                                <td>أمل عودة</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="signature float-end">
                    « توقیع المختار»
                    {/* <img className="sign-img img-fluid" src={`${process.env.PUBLIC_URL}/images/mm.jpg`} alt="Profile" /> */}
                </div>
                <div className="float-start">
                مرکز محافطه البصره<br/>
المختار : حیدر سالم داوود سلمان<br />
No:804
                </div>
                </div>

                {/* امضا */}
            <br />
            <br />
            <br />
                <Link to={'/d'} class="btn btn-primary mt-3">
        إضافة
        <i class="bi bi-plus-circle ms-1"></i>
    </Link>
    <div className="mt-3 d-flex justify-content-center align-items-center">
    <ul class="pagination">
        <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
        <li class="page-item"><a class="page-link" href="#">B1</a></li>
        <li class="page-item"><a class="page-link" href="#">B2</a></li>
        <li class="page-item"><a class="page-link" href="#">B3</a></li>
        <li class="page-item"><a class="page-link" href="#">B4</a></li>
        <li class="page-item"><a class="page-link" href="#">B5</a></li>
        <li class="page-item">
            <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>
</div>
    </div>

        </Layout>
    );
}

export default FiftComponent;