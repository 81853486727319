import { Link } from "react-router-dom";


function Sidebar() {
    return (
        <ul class="nav flex-column ">
            <h6 className="mokhtar">سجل قاعدة بيانات المواطنين</h6>
            <hr />
        <Link to={'/s'} className=" nav-link nav-item text-white sidebar-link mb-3" >
            <li class="nav-item mb-3  ">
                <i className="fa fa-file-archive float-end"></i>
                <span className=" ps-2 float-start">لوحة التحكم  </span>
            </li>
        </Link>
        {/* <Link to={'/b'} className=" nav-link nav-item text-white sidebar-link mb-3" >
            <li class="nav-item mb-3 ">
                <i className="fa fa-home-user float-end"></i>
                <span className="ps-2 float-start"> بيت </span>
            </li>
        </Link> */}
        <Link to={'/c'} className=" nav-link nav-item text-white sidebar-link mb-3" >
            <li class="nav-item mb-3  ">
                <i className="fa fa-users float-end"></i>
                <span className=" ps-2 float-start">قائمته الناس </span>
            </li>
        </Link>
    </ul>
    );
}

export default Sidebar;