import { useRef, useState , useEffect , useContext , useLayoutEffect } from "react";

import { useNavigate } from "react-router-dom";

function Login() {
    const [loading , setLoading] = useState(false);
    const [data , setData] = useState({
        mobile:"",
        password:""
    });
    const navigate = useNavigate();
    
    useEffect(() => {
        function convertToArabicNumbers(str) {
            return str.replace(/\d/g, function(d) {
                return '٠١٢٣٤٥٦٧٨٩'[d];
            });
        }
  
        document.querySelectorAll('input').forEach(function(input) {
            input.addEventListener('input', function() {
                this.value = convertToArabicNumbers(this.value);
            });
        });
  
    }, []); 

     function LoginTo() {
        navigate('/s')
    }
    
    return (
        <div className="login-container">
            <div className="login-card">
                <div className="header-login text-center text-muted">
                <i className="fa fa-user-circle fa-4x"></i>
                <h5 className="login-title">تسجيل الدخول</h5>
                </div>
                <form>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">عنوان البريد الإلكتروني</label>
                        <input type="email" className="form-control" id="email" placeholder="example@gmail.com" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">كلمة المرور</label>
                        <div className="input-group">
                        <span className="input-group-text border-start rounded-start">
                                <i className="bi bi-eye-slash"></i>
                            </span>
                            <input type="password" className="form-control border-start-0" id="password" placeholder="********" />
                        </div>
                    </div>
                    <button  onClick={LoginTo} type="submit" className="btn btn-primary w-100 login-button">تسجيل الدخول</button>
                </form>
                <div className="text-center mt-3">
                    <a href="#" className="reset-link">إعادة تعيين كلمة المرور؟</a>
                </div>
                {/* <div className="separator">
                    <span>أو</span>
                </div>
                <div className="text-center mt-3">
                    <a href="#" className="signup-link">حساب جديد</a>
                    <p className="signup-text">ليس لديك حساب حتى الآن؟</p>
                </div> */}
            </div>
        </div>
    );
}

export default Login; 