import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";

function Main({children}) {
    return (
        <div class="container-fluid">
            <div className="my-navbar">
            <Navbar />
            </div>
                        
        <div class="row">
            <div class="col-md-1 sidebar pt-3">
                <Sidebar />
            </div>

            <div class="col-md-11 main-content">
                <div class="row">
                    {children}
                </div>
            </div>
        </div>
    </div>
    );
}

export default Main;