import { Routes , Route } from 'react-router-dom';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './router/PrivateRoute';
import 'bootstrap-icons/font/bootstrap-icons.css';
import SecComponent from './pages/dashboard/SecComponent';
import ThirComponenet from './pages/dashboard/ThirComponenet';
import FortComponent from './pages/dashboard/FortComponent';
import FiftComponent from './pages/dashboard/FiftComponent';


function App() {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Routes>
                <Route path="/" element={<Login />}/>
                <Route path="/s" element={<Dashboard />}/>
                {/* <Route path="/b" element={<SecComponent />}/> */}
                {/* <Route path="/إضافة" element={<ThirComponenet />}/> */}
                <Route path="/d" element={<FortComponent />}/>
                <Route path="/c" element={<FiftComponent />}/>
            </Routes>
        </>
    );
}

export default App;