import React, { useState, useEffect } from 'react';
import Layout from "../layout/Layout";


function Dashboard() {
    const [factors, setFactors] = useState([]);
    const [stats, setStats] = useState({
        totalFactors: 0,
        creditFactors: 0,
        unsettledFactors: 0,
        pendingFactors: 0
    });
    useEffect(() => {
      function convertToArabicNumbers(str) {
          return str.replace(/\d/g, function(d) {
              return '٠١٢٣٤٥٦٧٨٩'[d];
          });
      }

      document.querySelectorAll('input').forEach(function(input) {
          input.addEventListener('input', function() {
              this.value = convertToArabicNumbers(this.value);
          });
      });

  }, []); 





    return (
        <Layout>
 <div className="container">

      {/* Search and Total Users */}
      <div className="row my-4 ">
        <div className="col-md-4 text-start ">
          <div className="   ms-2 bg-white p-3 rounded">
            <div className="form-switch">
              <input className="form-check-input mt-3" type="checkbox" id="flexSwitchCheckDefault" />
              <span className='ms-5 text-center'>                ١٢٣٥
              <br />
                جميع الناس</span>
            </div>
          </div>
        </div>
        <div className="col-md-8 bg-white p-3 rounded">
          <div className="input-group">
          <span class="input-group-text border-0" id="basic-addon1"> بحث</span>
            <input
              type="text"
              className="form-control border-end-0 rounded-start"
              aria-label="Search"
            />
            <span class="input-group-text border-start-0 border-end rounded" id="basic-addon1"><i className='fa fa-search'></i></span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="row">
        {/* <div className="col-md-8 mb-4">
          <div className="card shadow-none border-0">
            <div className="card-body">
                {<CalendarComponent />}
            </div>
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="card shadow-none border-0 txt">
            <div className="card-body">
              <h5 className="card-title">أحدث الأنشطة</h5>
              {/* محتوای فعالیت‌ها اینجا قرار می‌گیرد */}
            </div>
          </div>
        </div>
      </div>
    </div>
        </Layout>
    );
}

export default Dashboard;
