import { Link, useLocation, useNavigate } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  function Logout(e) {
    navigate('/login');
  }

  return (
    <div className="app-navbar mx-auto col-12 shadow">
      <div className="d-flex align-items-center justify-content-between">
        <div className="  text-start">
          {location.pathname === "/s" ? (
            <h3 className="page-title ps-3">لوحة التحكم</h3>
          ) : location.pathname === "/b" ? (
            <h3 className="page-title ps-3">بیت</h3>
          ) : location.pathname === "/c" ? (
            <h3 className="page-title ps-3">قائمته الناس</h3>
          ) : location.pathname === "/إضافة" ? (
            <h3 className="page-title ps-3">إضافة</h3>
          ) : (
            <h3 className="page-title ps-3">معلومات الأسرة</h3>
          )}
        </div>

        <div className="logo-container d-flex justify-content-center flex-grow-1">
          <img
            className="img-fluid rounded-pill logo"
            src={`${process.env.PUBLIC_URL}/images/dis.PNG`}
            alt="Logo"
          />
        </div>

        <div className="user-info d-flex justify-content-end">
          <div className="btn-group">
            <button
              className="btn btn-outline-default btn-sm dropdown-toggle border-0 profile-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="profile-img img-fluid"
                src={`${process.env.PUBLIC_URL}/images/user.png`}
                alt="Profile"
              />{" "}
              حیدر سالم داوود سلمان
            </button>
            <ul className="dropdown-menu dropdown-menu-end text-center">
              <li>
                <a className="dropdown-item" href="#">
                  الملف الشخصي
                </a>
              </li>
              <li onClick={Logout}>
                <a className="dropdown-item" href="#">
                  تسجيل الخروج
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
