import Main from "./main/Main";

function Layout({children}) {
    return (
        <Main>
            {children}
        </Main>
    );
}

export default Layout;